<template>
    <div>
        <div v-if="preloading">
            <div class="d-flex justify-content-center align-items-center preloading">
                <div class="loader-xl" />
            </div>
        </div>
        <div v-else>
            <div class="">
                <section class="box">
                    <b-tabs animated @input="onTabChange">
                        <b-tab-item
                            v-for="tab in tabs"
                            :key="tab.index"
                            :label="tab.label + ' (' + counts[tab.index] + ')'"
                            :icon="tab.icon"
                            :value="tab.index"
                        >
                            <OrdersTable :active-tab="activeTab" />
                        </b-tab-item>
                    </b-tabs>
                </section>
            </div>
        </div>
        <Toaster ref="toaster" />
    </div>
</template>

<script>
    import Toaster from '@/components/UI/Toaster'
    import { mapActions, mapState } from 'vuex'
    import OrdersTable from '@/components/Admin/Orders/OrdersTable'

    export default {
        name: 'SuiviAdmin',
        title: 'suivi-admin.title',
        components: {
            Toaster,
            OrdersTable
        },
        data: function () {
            return {
                tabs: [
                    {
                        label: `Toutes`,
                        icon: 'sitemap',
                        index: 'all'
                    },
                    {
                        label: 'En attente',
                        icon: 'clipboard-list',
                        index: 'a_valider'
                    },
                    {
                        label: 'Refusée',
                        icon: 'clipboard-list',
                        index: 'refuse'
                    },
                    {
                        label: 'A traiter',
                        icon: 'pencil-alt',
                        index: 'en_cours'
                    },
                    {
                        label: 'Annulées',
                        icon: 'ban',
                        index: 'annule'
                    },
                    {
                        label: 'Terminées',
                        icon: 'clipboard-check',
                        index: 'termine'
                    },
                    {
                        label: 'Erreurs',
                        icon: 'exclamation-circle',
                        index: 'error'
                    }
                ],
                //UI
                preloading: true,
                activeTab: 'all'
            }
        },
        created() {
            //this.getData();
            this.$store.state.orders.shouldComputeOrdersNotes = true
            this.getOrders()
                .then(() => {})
                .catch(error => {
                    console.log(error)
                    this.$store.dispatch('toastError', error)
                })
                .finally(() => {
                    this.preloading = false
                })
        },

        computed: {
            ...mapState('orders', {
                orders: state => state.orders,
                config: state => state.ordersConfig,
                counts: state => state.counts
            }),
            isLoading: {
                get() {
                    return this.$store.state.orders.isLoading
                },
                set(value) {
                    this.$store.commit('orders/setIsLoading', value)
                }
            }
        },

        methods: {
            ...mapActions('orders', ['getOrders']),
            onTabChange(status) {
                console.log(status)
                this.activeTab = status
                this.isLoading = true
                this.config.page = 1
                this.config.per_page = 100
                status = status === 'all' ? '' : status
                this.config.filters = {
                    status: status
                }
                this.$store.state.orders.shouldComputeOrdersNotes = true
                this.getOrders().finally(() => {
                    this.isLoading = false
                })
            }
        }
    }
</script>
